const cashmere = '#D0B896'
const peru     = '#D48C42'
const dallas   = '#714C29'
const heath    = '#5E0E18'
const gold     = '#c1b293'
const offwhite = '#eee9df'
const red      = '#de214b'

export default {
  colors: {
    text: offwhite,
    background: '#000',
    primary: heath,
    peru: peru,
    dallas: dallas,
    gold: gold,
    cashmere: cashmere,
    red: red,
    offwhite: offwhite
  },
  fonts: {
    body: '"Suisse Intl", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Bernase Roman',
  },
  Link: {
    textDecoration: 'none'
  },
  text: {
    heading: {
      fontFamily: 'heading',
      textTransform: 'uppercase',
      letterSpacing: '0.05em'
    }
  },
  lineHeights: {
    body: 1.25,
    heading: 1.125,
  },
  buttons: {
    primary: {
      color: 'white',
      backgroundColor: dallas,
    },
    outline: {
      color: dallas,
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 2px'
    },
  },
  styles: {
    root: {
      fontFamily: 'body'
    },
  },
}
